import React from 'react';
import {HeadingH2, PageContent} from "../styles/GlobalStyles";
import {Underline} from "../styles/Underline";
import {Button} from "../styles/Button";
import {StaticImage} from "gatsby-plugin-image";
import styled from "styled-components";


const KainosConstant = () => {

    return (
        <PageContent>

            <KainosItems>
                <HeadingH2>Kainos</HeadingH2>
                <Underline fluid primary small/>
                <FirstH5>Žaidimo kaina</FirstH5>
                <p>4 x žaidimo maišeliai (rožiniai arba žydros spalvos) - <b>60€</b><br/>
                    8 x žaidimo maišeliai (4 rožiniai, 4 žydros spalvos) - <b>90€</b><br/>
                    Žaidimo komplekto kaina su viena lenta - <b>280€</b><br/>
                    Žaidimo komplekto kaina su dviem lentomis - <b>380€</b></p>
                    <h5>Nuomos kaina</h5>
                <p>Vienos žaidimo lentos komplekto nuoma parai - <b>35€</b><br/>
                    Dviejų žaidimo lentų komplekto nuoma parai - <b>50€</b><br/>
                    (Nuomojant ilgiau negu parai <b>- 50% nuolaida</b> kitoms paroms)</p>
                <h6>I komplektą įeina:</h6>
                <KainosP>- Žaidimo lenta(-os),<br/>
                    - 8 x žaidimo maišeliai (4 rožiniai, 4 žydros spalvos),<br/>
                    - Maišelis (susidėti visus žaidimui skirtus maišelius, bei liniją ir taisykles),<br/>
                    - Speciali linija nuo kurios reikia mesti,<br/>
                    - Taisyklės</KainosP>
                <Button to="/kontaktai" big="true">Susisiekite su Mumis</Button>
            </KainosItems>

            <KainosImg>
                <StaticImage src="../assets/images/bohabo-lentos.png" placeholder="none" quality="100" alt="Bohabo rules kainos image"/>
            </KainosImg>

        </PageContent>
    );
};

export default KainosConstant;

const KainosItems = styled.div`
    grid-column: 1/-1;  
    text-align: center;
    align-self: center;
    justify-self: center;
    width: 100%;
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    grid-column: 1/7;
    text-align: left;
  }
`;

const KainosP = styled.p`
  color: ${props => props.theme.black};
  font-size: clamp(14px, 3vw, 16px);
  margin: 0 auto 2rem;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    margin: 0rem 0 3rem;
  }
`;

const FirstH5 = styled.h5`
  margin-top: 3rem;
  
  // @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
  //   margin: 3rem 0 0 0;
  // }
`;

const KainosImg = styled.div`
  grid-column: 1/-1;
  justify-self: center;
  width: 100%;
  max-width: 500px;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    grid-row: 1/1;
    grid-column: 7/13;
    transform: translateX(4px);
    max-width: 100%;
    align-self: center;
  }
`;