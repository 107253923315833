import React from "react"
import Layout from "../components/Layout"
import {PageWrapper, PageContainer} from "../styles/GlobalStyles";
import KainosContent from "../constants/kainos";
import Seo from "../components/SEO";

const AboutPage = () => {

    return (
        <Layout>
            <Seo title="Cornhole Žaidimo Kainos - Bohabo Cornhole Parduotuvė"
                 description="Ieškote kur pirkti Cornhole žaidimą? Cornhole žaidimo lentos, maišeliai, įrangos nuoma - visas kainas rasite mūsų Bohabo Cornhole parduotuvėje."
            />
            <PageWrapper>
                <PageContainer>
                    <KainosContent/>
                </PageContainer>
            </PageWrapper>
        </Layout>
    )
};

export default AboutPage;